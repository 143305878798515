import React, { useContext, useEffect, useState } from "react";
import { get } from "../API/base";
import { ImageTimeline } from "../components/timelinepage/ImageTimeline";
import { LoadingContext } from "../contexts/loadingContext";
import "./TimelinePage.scss";

export const TimelinePage = () => {
  const [loading, setloading] = useContext(LoadingContext);
  const [timeline, setTimeline] = useState([]);
  const [years, setYears] = useState([]);

  useEffect(() => {
    setloading(true);
    //timeline
    get(`oorali/timeline/event-view/`)
      .then((data) => {
        console.log("%cTimeline images page", "color:yellow", data.data);
        setTimeline(data?.data);

        get(`oorali/timeline/year-view/`)
          .then((data) => {
            console.log("%cTimeline years", "color:yellow", data.data);
            setYears(data?.data);

            setloading(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  const getmonth = (month) => {
    if (month === 1) return "JAN";
    else if (month === 2) return "FEB";
    else if (month === 3) return "MAR";
    else if (month === 4) return "APR";
    else if (month === 5) return "MAY";
    else if (month === 6) return "JUN";
    else if (month === 7) return "JUL";
    else if (month === 8) return "AUG";
    else if (month === 9) return "SEP";
    else if (month === 10) return "OCT";
    else if (month === 11) return "NOV";
    else if (month === 12) return "DEC";
    else return month;
  };

  return (
    <div className="TimelinePage">
      <div className="timeline-sec">
        <div className="timeline-title">Timeline</div>
        <div className="main-timeline-con">
          <div className="date-con">
            {years?.map((y) => (
              <a href={"#" + y?.year} key={y?.year}>
                {y?.year}
              </a>
            ))}
          </div>
          <div className="timeline-con">
            {timeline?.map((t, index) => (
              <div className="yearly-timeline" id={t.year} key={index}>
                {t?.months?.map((m) => (
                  <>
                    <div className="year">
                      {t?.year}
                      <span>|</span>
                      {getmonth(m?.month)}
                    </div>
                    <div className="card-sec">
                      {m?.events?.map((e, index) => (
                        <React.Fragment key={index}>
                          <ImageTimeline
                            image={e?.image}
                            image1={e?.image1}
                            image2={e?.image2}
                            image3={e?.image3}
                            image4={e?.image4}
                            name={e?.program_name}
                            place={e?.place}
                            isfullwidth={e?.is_fullwidth}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
