import React, { useContext, useEffect, useState } from "react";
import "./BandPage.scss";

import bg from "../images/band/bandland1.jpg";

import { BandMemberComp } from "../components/bandpage/BandMemberComp";
import { LoadingContext } from "../contexts/loadingContext";
import { get } from "../API/base";

export const BandPage = () => {
  const [loading, setloading] = useContext(LoadingContext);

  const [onclick, setonclick] = useState("");
  const [wallpapper, setWallpapper] = useState();
  const [about, setAbout] = useState("");
  const [bandMem, setBandMem] = useState([]);
  const [bandTec, setBandTec] = useState([]);

  useEffect(() => {
    setloading(true);
    //wallpapper
    get(`oorali/band/bandwallpaper-view-image/`)
      .then((data) => {
        console.log("%cband wallpapper", "color:yellow", data.data);
        setWallpapper(data?.data[0]?.image || bg);
        setAbout(data?.data[0]?.about);

        //band members
        get(`oorali/band/band-viewMember/`)
          .then((data) => {
            console.log("%cband members", "color:yellow", data.data);
            setBandMem(data?.data);

            //technical members
            get(`oorali/band/technicalCrew-view/`)
              .then((data) => {
                console.log("%ctechnical members", "color:yellow", data.data);
                setBandTec(data?.data);
                setloading(false);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="BandPage">
      <div className="image-con">
        <img src={wallpapper} alt="oorali" />
      </div>
      <div className="band-members">
        <div
          className="readmore-expanded"
          dangerouslySetInnerHTML={{
            __html: `<span>${about}</span>`,
          }}
        ></div>
        <div className="title">Band members</div>
      </div>
      <div className="members-sec">
        <div className="section-again">
          {bandMem.map((b) => (
            <React.Fragment key={b?.id}>
              <BandMemberComp
                name={b?.name}
                image={b?.image}
                about={b?.about}
                color={b?.color}
                onclick={onclick}
                setonclick={setonclick}
              />
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="technical-sec">
        <div className="title">Technical crew</div>
        <div className="members-sec">
          <div className="section-again2">
            {bandTec.map((b) => (
              <React.Fragment key={b?.id}>
                <BandMemberComp
                  name={b?.name}
                  image={b?.image}
                  about={b?.about}
                  color={b?.color}
                  onclick={onclick}
                  setonclick={setonclick}
                />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="white-space"></div>
    </div>
  );
};
