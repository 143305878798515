import React, { useEffect, useState } from "react";
import "./ImageDownComp.scss";
import downloadLogo from "../../images/icons/downloadLogo.svg";

export const ImageDownComp = ({
  assets,
  setViewMoreAssets,
  viewMoreAssets,
}) => {
  let allassets = assets;
  const [assetsView, setAssetsView] = useState([]);

  useEffect(() => {
    if (viewMoreAssets === true) {
      setAssetsView(allassets.slice(0, 6));
    } else {
      setAssetsView(allassets);
    }
  }, [allassets, viewMoreAssets]);

  return (
    <div className="ImageDownComp">
      {assetsView?.map((tile) => (
        <div className="asset" key={tile?.id}>
          <img
            className="asset-img"
            src={tile?.image}
            alt={tile?.id}
            width={300}
          />
          <div className="bot-sec">
            <div className="name">{tile?.name}</div>
            <div className="download">
              <a href={tile?.image} target="_blank" rel="noopener noreferrer">
                <img
                  src={downloadLogo}
                  alt="download"
                  width="24px"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
