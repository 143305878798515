import React from "react";
import Slider from "react-slick";
import "./SlickComp.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { SlickCardComp } from "./SlickCardComp";

const SlickComp = ({ data, title, comp }) => {
  const settings = {
    arrows: true,
    className: "center",
    centerMode: true,
    centerPadding: "0px",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow:
      data?.length >= 4
        ? 4
        : data?.length === 3
        ? 3
        : data?.length === 2
        ? 2
        : data?.length === 1
        ? 1
        : 0,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    swipeToSlide: true,
    pauseOnHover: true,
    initialSlide: 0,
    // adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          arrows: true,

          className: "center",
          centerMode: true,
          // centerPadding: "-10px",
          slidesToShow:
            data?.length >= 3
              ? 3
              : data?.length === 2
              ? 2
              : data?.length === 1
              ? 1
              : 0,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1090,
        settings: {
          arrows: false,

          className: "center",
          centerMode: true,
          // centerPadding: "-10px",
          slidesToShow: data?.length >= 2 ? 2 : data?.length === 1 ? 1 : 0,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          arrows: false,

          className: "center",
          centerMode: true,
          // centerPadding: "-10px",
          slidesToShow: data?.length >= 1 ? 1 : 0,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="SlickComp">
      <Slider {...settings}>
        {data.map((d) => (
          <React.Fragment key={d?.id}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "470px",
              }}
            >
              <SlickCardComp data={d} />
            </div>
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
};

export default SlickComp;
