import React, { useContext, useEffect, useState } from "react";
import "./SadhanaPage.scss";
import sadhana from "../images/sadhana.png";
import "../Animation.scss";
import { ProjectCard } from "../components/sadhanapage/ProjectCard";
import { LoadingContext } from "../contexts/loadingContext";
import { get } from "../API/base";

export const SadhanaPage = () => {
  const [loading, setloading] = useContext(LoadingContext);

  const [wallpapper, setWallpapper] = useState();
  const [about, setAbout] = useState("");
  const [video, setVideo] = useState();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setloading(true);
    //wallpapper
    get(`oorali/sadhana/sadhanaWallpaper-view/`)
      .then((data) => {
        console.log("%csadhana wallpapper", "color:yellow", data.data);
        setWallpapper(data?.data[0]?.image || sadhana);
        setAbout(data?.data[0]?.about);

        //videos
        get(`oorali/sadhana/sadhanavideo-view/`)
          .then((data) => {
            console.log("%csadhana videos", "color:yellow", data.data);
            setVideo(data?.data[0]?.video);

            //projects
            get(`oorali/sadhana/sadhanaproject-view/`)
              .then((data) => {
                console.log("%csadhana project", "color:yellow", data.data);
                setProjects(data?.data);
                setloading(false);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="SadhanaPage">
      {/* <div className="title-sec">SADHANA Centre For Creative Practice</div> */}
      <div className="sadhana-con">
        <img src={wallpapper} alt="sadhana" />
      </div>
      <div
        className="txt-con"
        dangerouslySetInnerHTML={{
          __html: `<span>${about}</span>`,
        }}
      ></div>
      <div className="sadhana-place">
        <iframe
          loading="lazy"
          src={video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="title-sec">Productions and Collaborations</div>

      <div className="project-sec">
        {projects?.map((p) => (
          <div className="card" key={p?.id}>
            <ProjectCard
              image={p?.main_image}
              name={p?.name}
              year={p?.date}
              about={p?.about}
              optImage1={p?.optional_image1}
              optImage2={p?.optional_image2}
            />
            {/* <SlickProjectCard data={projects} />   */}
          </div>
        ))}
      </div>
      <div className="donate">
        <span>
          If you are reading this, we are already connected .The value of what
          we create and share is based on what it means to you. If you feel that
          our effort is making your time worthwhile feel free to donate . Your
          support means a lot to us. Art salute
        </span>
        <button
          className="new-btn-pri"
          onClick={() => {
            var modal = document.getElementById("myDonateModal");
            modal.style.display = "flex";
          }}
        >
          DONATE
        </button>
      </div>
      <div id="myDonateModal" className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h3>{"Donate"}</h3>
            <span
              className="close"
              onClick={() => {
                var modal = document.getElementById("myDonateModal");
                modal.style.display = "none";
              }}
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            <div className="bank-details-con">
              <div className="bank">State Bank of India</div>
              <div className="bank-details">
                Account Number: <span> 67212312151</span>
              </div>
              <div className="bank-details">
                IFSC Code:<span> SBIN0070166</span>
              </div>
              <div className="bank-details">
                PAN Number :<span> AAJAS0945A</span>
              </div>
              <div className="bank-details">
                GPAY Number: <span>9061546756</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
