import React, { useContext, useEffect, useState } from "react";
import "./Homepage.scss";
import "../Animation.scss";
import SlickComp from "../components/homepage/SlickComp";
import VideoSlickComp from "../components/homepage/VideoSlickComp";
import { SlickCardComp } from "../components/homepage/SlickCardComp";
import { Link } from "react-router-dom";
import { MusicLinkSec } from "../components/homepage/MusicLinkSec";
import { LoadingContext } from "../contexts/loadingContext";
import { get } from "../API/base";
import homepagebg from "../images/Homepage.jpg";
import logogif from "../images/gif/logo.gif";
import ooralibg from "../images/oorali-express.jpg";

const Homepage = () => {
  const [loading, setloading] = useContext(LoadingContext);

  const [bgimage, setbgimage] = useState();
  const [happenings, setHappenings] = useState([]);
  const [featuredVideo, setFeaturedVideo] = useState([]);
  const [featuredMusic, setFeaturedMusic] = useState([]);
  const [ooraliExp, setOoraliExp] = useState();

  useEffect(() => {
    //wallpapper
    setloading(true);
    get(`oorali/spotlight/spotlight-view/`)
      .then((data) => {
        console.log("%cspotlight", "color:yellow", data.data[0].image);
        setbgimage(data?.data[0]?.image || homepagebg);

        //latest happenings
        get(`oorali/latest-happenings/latestHappenings-view/`)
          .then((data) => {
            console.log("%clatest happenings", "color:yellow", data.data);
            setHappenings(data?.data);

            //featured video
            get(`oorali/videos/featured-video-view/`)
              .then((data) => {
                console.log("%cfeatured video", "color:yellow", data.data);
                setFeaturedVideo(data?.data);

                //music that defines us
                get(`oorali/music/music-that-defines-us/`)
                  .then((data) => {
                    console.log(
                      "%cmusic that defines",
                      "color:yellow",
                      data.data
                    );
                    setFeaturedMusic(data?.data?.reverse());

                    //oorali express
                    get(`oorali/oorali-express/ooraliExpressHome-view/`)
                      .then((data) => {
                        console.log(
                          "%cOorali express",
                          "color:yellow",
                          data.data
                        );
                        setOoraliExp(data?.data[0]?.image || ooralibg);
                        setloading(false);
                      })
                      .catch((err) => console.log(err));
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="Homepage">
      <div className="ellipse" style={{ backgroundImage: `url(${bgimage})` }}>
        <img
          src={logogif}
          alt="oorali"
          className="logogif"
          onClick={() => {
            var modal = document.getElementById("myLogoModal");
            modal.style.display = "flex";
          }}
        />
      </div>
      <div className="latest-happ">
        <span>Whats new</span>
        <div className="slick-car">
          <SlickComp
            data={happenings}
            comp={SlickCardComp}
            title="Latest Happenings"
          />
        </div>
      </div>
      <div className="featured-video">
        <VideoSlickComp title={"Featured videos"} data={featuredVideo} />
      </div>
      <div className="music-link-sec">
        <MusicLinkSec data={featuredMusic} />
      </div>
      <div
        className="oorali-express"
        style={{ backgroundImage: `url(${ooraliExp})` }}
      >
        <div className="oorali-text">
          <div className="title">OORALI EXPRESS</div>
          <div className="sub">
            Imagine a bus drives down a remote location, parks, opens up into a
            performing arena and presents Oorali experience. That bus is called
            Oorali Express. The pinnacle of their dreams, art in motion and a
            machine transcending boundaries.
          </div>
          <div className="read-more">
            <Link to="/OoraliexpressPage">
              <button className="new-btn-sec">READ MORE</button>
            </Link>
          </div>
        </div>
      </div>
      <div id="myLogoModal" className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h3>{"OORALI"}</h3>
            <span
              className="close"
              onClick={() => {
                var modal = document.getElementById("myLogoModal");
                modal.style.display = "none";
              }}
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            <p>
              Oorali is a contemporary conversational band defiantly attempting
              to reinvent itself in pursuit of original expression through art.
              A collective of talented actors, musicians, writers and visual
              artists who constantly collaborate to create unique experiences,
              Oorali’s art is born out of interactions with the society
              contributing to their highly adaptive, customized performances. An
              Oorali performance is a spectacle; a creative mix of music,
              theatre, visuals, poetry and song.
            </p>
            <Link to="/BandPage">Read more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
