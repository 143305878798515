import React from "react";
import "./ProjectCard.scss";

export const ProjectCard = ({ image, name, about, optImage1, optImage2 }) => {
  return (
    <div className="flip-cardd">
      <div
        className="flip-card-inner"
        onClick={() => {
          var modal = document.getElementById(name);
          modal.style.display = "flex";
        }}
      >
        <div className="flip-card-front">
          <img src={image} alt={name} />
          <div className="name">{name}</div>
          {/* <div className="sub">{year}</div> */}
        </div>
        <div className="flip-card-back">
          <div className="about">Read more</div>
        </div>
      </div>

      {/* modal */}
      <div id={name} className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{name}</h2>
            <span
              className="close"
              onClick={() => {
                var modal = document.getElementById(name);
                modal.style.display = "none";
                console.log(modal.style.display);
              }}
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            <div className="img-con">
              <img src={image} alt="poster" />
              {optImage1 && <img src={optImage1} alt="poster" />}
              {optImage2 && <img src={optImage2} alt="poster" />}
            </div>
            <p
              className="lyrics"
              dangerouslySetInnerHTML={{
                __html: `<span>${about}</span>`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
