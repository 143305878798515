import React from "react";
import "./SlickCardComp.scss";
// import happenings from "../../images/home/happenings.png";
export const SlickCardComp = ({ data }) => {
  return (
    <div className="SlickCardComp">
      <a href={data?.url} target="_blank" rel="noreferrer">
        <img src={data?.image} alt="Empty" />
        <div className="date">{data?.date}</div>
        <div className="title">{data?.description}</div>
      </a>
    </div>
  );
};
