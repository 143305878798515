import { createContext, useState } from "react";

const LoadingContext = createContext(null);

const LoadingProvider = ({ children }) => {
  const [loading, setloading] = useState(false);

  return (
    <LoadingContext.Provider value={[loading, setloading]}>
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingContext, LoadingProvider };
