import React, { useContext, useEffect, useState } from "react";
import "./OoraliExpressPage.scss";
import { ExpressCarComp } from "../components/ooraliexpresspage/ExpressCarComp";
import bg from "../images/oorali express/bgOorali.jpg";
import { ProjectCard } from "../components/sadhanapage/ProjectCard";
import { LoadingContext } from "../contexts/loadingContext";
import { get } from "../API/base";

export const OoraliExpressPage = () => {
  const [loading, setloading] = useContext(LoadingContext);

  const [bgimage, setbgimage] = useState();
  const [about, setabout] = useState("");
  const [video, setVideo] = useState();
  const [carousel, setCarousel] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setloading(true);
    //wallpapper
    get(`oorali/oorali-express/ooraliExpressWallpaper-view/`)
      .then((data) => {
        console.log("%cExpress wallpapper", "color:yellow", data.data);
        setbgimage(data?.data[0]?.image || bg);
        setabout(data?.data[0]?.about);
        //video
        get(`oorali/oorali-express/ooraliExpressVideo-view/`)
          .then((data) => {
            console.log("%cExpress video", "color:yellow", data.data);
            setVideo(data?.data[0]?.video);

            //projects
            get(`oorali/oorali-express/ooraliExpressproject-view/`)
              .then((data) => {
                console.log("%cExpress projects", "color:yellow", data.data);
                setProjects(data?.data);

                //carousel
                get(`oorali/oorali-express/buscarousel-view/`)
                  .then((data) => {
                    console.log(
                      "%cExpress carousel",
                      "color:yellow",
                      data.data
                    );
                    setCarousel(data?.data);
                    setloading(false);
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="OoraliExpressPage">
      <div className="image-con">
        <img src={bgimage} alt="oorali" />
      </div>
      <div
        className="about"
        dangerouslySetInnerHTML={{
          __html: `<span>${about}</span>`,
        }}
      ></div>
      <div className="video-sec">
        <iframe
          loading="lazy"
          src={video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="title">Productions and Collaborations</div>
      <div className="projectcard-sec">
        {projects?.map((p) => (
          <div className="card" key={p?.id}>
            <ProjectCard
              image={p?.main_image}
              name={p?.name}
              about={p?.about}
              optImage1={p?.optional_image1}
              optImage2={p?.optional_image2}
            />
          </div>
        ))}
      </div>
      <ExpressCarComp data={carousel} />
    </div>
  );
};
