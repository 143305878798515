import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ExpressCarComp.scss";

export const ExpressCarComp = ({ data }) => {
  const settings = {
    fade: true,
    dots: true,
    arrows: false,
    infinite: true,
    // speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    // swipeToSlide: true,
  };
  return (
    <div className="ExpressCarComp">
      <Slider {...settings}>
        {data?.map((d) => (
          <React.Fragment key={d?.id}>
            <div className="section">
              <img
                src={d?.image}
                alt="oorali express"
                className="image-express"
              />
            </div>
          </React.Fragment>
        ))}
      </Slider>
    </div>
  );
};
