import React from "react";
import "./Footer.scss";
const Footer = () => {
  return (
    <div className="Footer">
      <div className="tbh">
        Powered by
        <a
          href="https://www.techbyheart.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Techbyheart
        </a>
      </div>
    </div>
  );
};

export default Footer;
