import React, { useContext, useEffect, useState } from "react";
import { LoadingContext } from "../contexts/loadingContext";
import { ImageDownComp } from "../components/mediapage/ImageDownComp";
import ImageGrid from "../components/mediapage/ImageGrid";
import { VideoComp } from "../components/mediapage/VideoComp";
import "./MediaPage.scss";
import { get } from "../API/base";

export const MediaPage = () => {
  const [loading, setloading] = useContext(LoadingContext);

  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [assets, setAssets] = useState([]);

  const [viewMoreImages, setViewMoreImages] = useState(false);
  const [viewMoreAssets, setViewMoreAssets] = useState(false);

  useEffect(() => {
    setloading(true);
    //videos
    get(`oorali/videos/video-view/`)
      .then((data) => {
        console.log("%cvideos", "color:yellow", data.data);
        setVideos(data?.data);

        //images
        get(`oorali/assets/image-view/`)
          .then((data) => {
            console.log("%cimages", "color:yellow", data.data);
            setImages(data?.data);

            //assets
            get(`oorali/assets/asset-view/`)
              .then((data) => {
                console.log("%cassets", "color:yellow", data.data);
                setAssets(data?.data);
                setloading(false);
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="MediaPage">
      <div className="media-con">
        <div className="media-video-con">
          <div className="video-title">Videos</div>
          <div className="videos-con">
            {videos?.map((v) => (
              <VideoComp
                key={v?.id}
                thumbnail={v?.thumbnail}
                title={v?.headline}
                link={v?.url}
              />
            ))}
          </div>
          <div className="view-more-con">
            <button className="view-more hvr-bounce-to-top">
              <a
                href="https://www.youtube.com/channel/UCdDsPbKjoxA0ucBfZ1R18lg/featured"
                target="_blank"
                rel="noopener noreferrer"
              >
                View more
              </a>
            </button>
          </div>
        </div>
        <div className="media-image-con">
          <div className="image-title">Images</div>
          <div className="image-con">
            <ImageGrid
              images={images}
              viewMoreImages={viewMoreImages}
              setViewMoreImages={setViewMoreImages}
            />
          </div>
          {images?.length > 6 ? (
            <div className="view-more-con">
              <button
                className="view-more hvr-bounce-to-top"
                onClick={() => setViewMoreImages(!viewMoreImages)}
              >
                {viewMoreImages ? "View more" : "View less"}
              </button>
            </div>
          ) : null}
        </div>

        <div className="media-assets-con">
          <div className="assets-title">Downloadables</div>
          <div className="assets-con">
            <ImageDownComp
              assets={assets}
              viewMoreAssets={viewMoreAssets}
              setViewMoreAssets={setViewMoreAssets}
            />
          </div>
          {assets?.length > 6 ? (
            <div className="view-more-con">
              <button
                className="view-more hvr-bounce-to-top"
                onClick={() => setViewMoreAssets(!viewMoreAssets)}
              >
                {viewMoreAssets ? "View more" : "View less"}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
