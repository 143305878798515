import React, { useState } from "react";
import "./AboutUs.scss";
import instagram1 from "../../images/social/instagram1.svg";
import facebook1 from "../../images/social/facebook1.svg";
import youtube1 from "../../images/social/youtube1.svg";
import soundcloud1 from "../../images/social/soundcloud1.svg";
import twitter1 from "../../images/social/twitter1.svg";
import { ModalComp } from "./ModalComp";
export const AboutUs = () => {
  const [email, setemail] = useState("");

  const sendEmail = () => {
    window.open(
      "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@oorali.in&body=Hi,"
    );
  };

  return (
    <div className="AboutUs">
      <div className="left-abt">
        <div className="about-us-title">
          <span>Connect to us via our socials</span>
        </div>

        <div className="social-icons-footer">
          <a
            href="https://www.instagram.com/ooralitheband/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="cir-btn-con hvr-radial-out">
              <img src={instagram1} alt="instagram1" />
            </div>
          </a>
          <a
            href="https://www.facebook.com/ooralitheband/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="cir-btn-con hvr-radial-out">
              <img src={facebook1} alt="facebook1" />
            </div>
          </a>
          <a
            href="https://www.youtube.com/channel/UCdDsPbKjoxA0ucBfZ1R18lg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="cir-btn-con hvr-radial-out">
              <img src={youtube1} alt="youtube1" />
            </div>
          </a>
          <a
            href="https://soundcloud.com/user-130754949"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="cir-btn-con hvr-radial-out">
              <img src={soundcloud1} alt="soundcloud1" />
            </div>
          </a>
          <a
            href="https://twitter.com/ooralitheband?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="cir-btn-con hvr-radial-out">
              <img src={twitter1} alt="twitter1" />
            </div>
          </a>
        </div>
        <div className="btn-con-new">
          <ModalComp />
        </div>
      </div>
      <div className="right-abt">
        <div className="title">
          <span>Sign up for latest updates</span>
        </div>

        <form onSubmit={onsubmit}>
          <input
            required
            className="email"
            type="email"
            placeholder="Enter email here"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
          <button
            type="submit"
            className="new-btn-pri-small"
            onClick={() => sendEmail(email)}
          >
            JOIN US
          </button>
        </form>
      </div>
    </div>
  );
};
