import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import menuicon from "../../images/menuicon.png";
import "./NewNavbar.scss";
import logoround from "../../images/logo-round.png";
import logotext from "../../images/logo-text.png";
import { LoadingContext } from "../../contexts/loadingContext";

export const NewNavbar = () => {
  const [loading, setloading] = useContext(LoadingContext);

  let location = useLocation();

  const [ishome, setishome] = useState("");
  useEffect(() => {
    setishome(location.pathname);
  }, [location.pathname]);

  function myFunction() {
    window.scrollTo(0, 0);
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }
  // var prevScrollpos = window.pageYOffset;
  // window.onscroll = function () {
  //   var currentScrollPos = window.pageYOffset;
  //   if (prevScrollpos > currentScrollPos) {
  //     document.getElementById("myTopnav").style.top = "0";
  //   } else {
  //     document.getElementById("myTopnav").style.top = "-68px";
  //   }
  //   prevScrollpos = currentScrollPos;
  // };
  return (
    <div className="NewNavbar">
      <div className="topnav" id="myTopnav">
        <Link to="/">
          <div className="rotate-center-parent">
            <img
              className={loading ? "rotate-center" : ""}
              src={logoround}
              alt="logoround"
              width="30px"
            />
            {ishome !== "/" ? (
              <img src={logotext} alt="OORALI" width="auto" height="30px" />
            ) : (
              ""
            )}
          </div>
        </Link>
        <div className="right-sec">
          <Link to="/BandPage" onClick={myFunction}>
            BAND
          </Link>
          <Link to="/MusicPage" onClick={myFunction}>
            MUSIC
          </Link>
          <Link to="/MediaPage" onClick={myFunction}>
            MEDIA
          </Link>
          <Link to="/OoraliexpressPage" onClick={myFunction}>
            OORALI EXPRESS
          </Link>
          <Link to="/TimelinePage" onClick={myFunction}>
            TIMELINE
          </Link>
          <Link to="/TourPage" onClick={myFunction}>
            TOUR
          </Link>
          <Link to="/SadhanaPage" onClick={myFunction}>
            SADHANA
          </Link>
          <Link to="/ShopPage" onClick={myFunction}>
            SHOP
          </Link>
        </div>
        <div className="icon">
          <img
            src={menuicon}
            alt="menu"
            onClick={myFunction}
            width="25px"
            className="heartbeat"
          />
        </div>
      </div>
    </div>
  );
};
