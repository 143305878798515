import React from "react";
import "./BandMemberComp.scss";

export const BandMemberComp = ({
  name,
  image,
  about,
  color,
  onclick,
  setonclick,
}) => {
  return (
    <div
      className="BandMemberComp"
      onClick={() => {
        if (onclick === name) {
          setonclick("");
        } else {
          setonclick(name);
        }
      }}
    >
      <div className={onclick === name ? "main-sec-hover" : "main-sec"}>
        <div className="img-con">
          <img src={image} alt={name} />
        </div>
        <div
          className="color-screen"
          style={{ backgroundColor: `${color}` }}
        ></div>
        <div className="mem-name">{name}</div>
        <div className="mem-about" style={{ backgroundColor: `${color}` }}>
          {about}
        </div>
      </div>
    </div>
  );
};
