import React, { useEffect, useState } from "react";
import "./MusicPlayer.scss";

import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";

export const MusicPlayer = ({ song, cover, name }) => {
  const [audioLists, setAudioLists] = useState([]);

  useEffect(() => {
    setAudioLists([{ musicSrc: song, name: name, cover: cover }]);
    console.log(audioLists);
  }, [cover, name, song]);

  return (
    <div className="MusicPlayer">
      <ReactJkMusicPlayer
        quietUpdate
        clearPriorAudioLists
        seeked={false}
        audioLists={audioLists}
        theme="dark"
        glassBg={true}
        toggleMode={false}
        showThemeSwitch={false}
        showDownload={false}
        responsive={false}
        showPlayMode={false}
        mode="full"
        showLyric={false}
        autoPlayInitLoadPlayList={false}
        autoPlay={true}
      />
    </div>
  );
};
