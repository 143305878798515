import React from "react";
import "./ImageTimeline.scss";

export const ImageTimeline = ({
  image,
  image1,
  image2,
  image3,
  image4,
  name,
  place,
  isfullwidth,
}) => {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  return (
    <div className="ImageTimeline">
      <div
        onClick={() => {
          var modal = document.getElementById(image);
          console.log(modal);
          modal.style.display = "flex";
        }}
      >
        {isfullwidth ? (
          <img
            id="imageid"
            className="fullwidth"
            src={baseURL + image}
            alt={image}
            loading="lazy"
          />
        ) : (
          <img
            id="imageid"
            className="notfullwidth"
            src={baseURL + image}
            alt={image}
            loading="lazy"
          />
        )}
        <div className="bot-sec">
          {/* <div className="date">{name}</div>
          <div className="place">{place}</div> */}
        </div>
      </div>
      {/* modal */}
      <div id={image} className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h4>{name + " ( " + place + " ) "}</h4>
            <span
              className="close"
              onClick={() => {
                var modal = document.getElementById(image);
                modal.style.display = "none";
                console.log(modal.style.display);
              }}
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            <div className="img-con">
              <img src={baseURL + image} alt="poster" loading="lazy" />
              {image1 && (
                <img src={baseURL + image1} alt="poster" loading="lazy" />
              )}
              {image2 && (
                <img src={baseURL + image2} alt="poster" loading="lazy" />
              )}
              {image3 && (
                <img src={baseURL + image3} alt="poster" loading="lazy" />
              )}
              {image4 && (
                <img src={baseURL + image4} alt="poster" loading="lazy" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
