import React from "react";
import "./ModalComp.scss";
import { MdCall, MdEmail, MdLocationOn } from "react-icons/md";

export const ModalComp = () => {
  // Get the modal
  var modal = document.getElementById("myModal");

  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = "none";
    }
  };
  return (
    <div className="ModalComp">
      {/* <button id="myBtn">Open Modal</button> */}
      <button
        id="myBtn"
        className="new-btn-pri-small"
        onClick={() => {
          var modal = document.getElementById("myModal");
          modal.style.display = "flex";
        }}
      >
        CONTACT US
      </button>

      <div id="myModal" className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Contact Us</h2>
            <span
              className="close"
              onClick={() => {
                var modal = document.getElementById("myModal");
                modal.style.display = "none";
              }}
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            <p>
              <MdLocationOn className="contact-icon" />
              <span>Sadhana House, Manakody PO, Thrissur, Kerala, 680012</span>
            </p>
            <p>
              <MdCall className="contact-icon" />
              <span>9645789205, 9544010273</span>
            </p>
            <p>
              <MdEmail className="contact-icon" />
              <span>ooralitheband@gmail.com</span>
            </p>
          </div>
          {/* <div className="modal-footer">
            <h3>Modal Footer</h3>
          </div> */}
        </div>
      </div>
    </div>
  );
};
