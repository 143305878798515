import React, { useContext } from "react";
import "./MusicLinkSec.scss";

import { useHistory } from "react-router-dom";
import { MusicContext } from "../../contexts/musicContext";

export const MusicLinkSec = ({ data }) => {
  const [music, setMusic] = useContext(MusicContext);

  let history = useHistory();
  return (
    <div className="MusicLinkSec">
      <div className="title">Our music</div>
      <div className="album-sec">
        {data?.map((d) => (
          <div
            key={d?.id}
            className="music-con"
            onClick={() => {
              setMusic(d);
              history.push("/MusicPage");
            }}
          >
            <img src={d?.thumbnail} alt="oorali tribe" />
            <div className="listen-now-con">Listen</div>
          </div>
        ))}
      </div>
    </div>
  );
};
