import React, { useEffect } from "react";
import "./PlayListComp.scss";
import playbutton from "../../images/icons/playbutton1.svg";
import playing from "../../images/icons/audio.png";

export const PlayListComp = ({
  image,
  name,
  year,
  lyrics,
  link,
  musiclink,
  setmusiclink,
  setimage,
  setname,
  setyear,
  setlyrics,
}) => {
  return (
    <div>
      {musiclink === link ? (
        <div
          className="now-playing"
          onClick={() => {
            setmusiclink(link);
          }}
        >
          <img src={image} alt={name} />
          <div className="name">{name}</div>
          <div className="bot-sec">
            <div className="sub">{year}</div>
            <div className="now">
              <img src={playing} alt="NOW PLAYING" />
            </div>
          </div>
        </div>
      ) : (
        <div
          className="PlayListComp"
          onClick={() => {
            setmusiclink(link);
            setimage(image);
            setname(name);
            setyear(year);
            setlyrics(lyrics);
          }}
        >
          <div className="image-con">
            <img className="music-image" src={image} alt={name} />
            <img className="hover-play" src={playbutton} alt="play" />
          </div>

          <div className="name">{name}</div>
          <div className="sub">{year}</div>
        </div>
      )}
    </div>
  );
};
