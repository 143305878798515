import React, { useEffect, useState } from "react";
import "./ImageGrid.scss";
export default function ImageGrid({
  images,
  setViewMoreImages,
  viewMoreImages,
}) {
  let allimages = images;
  const [imagesView, setImagesView] = useState([]);

  useEffect(() => {
    if (viewMoreImages === true) {
      setImagesView(allimages.slice(0, 6));
    } else {
      setImagesView(allimages);
    }
  }, [allimages, viewMoreImages]);

  return (
    <div className="ImageGrid">
      <div className="item">
        {imagesView?.map((tile) => (
          <img
            key={tile?.id}
            src={tile?.image}
            alt={tile?.id}
            loading="lazy"
            // width={tile.width / 10}
            // height={tile.height / 10}
          ></img>
        ))}
      </div>
    </div>
  );
}
