import React from "react";
import "./TourComp.scss";
import arrow from "../../images/icons/arrow.svg";

export const TourComp = ({ place, date, district, poster, about, link }) => {
  console.log(place, date, district, poster, about, link);
  return (
    <div className="TourComp">
      <div className="yellow-con">
        <div className="detail">{place}</div>
        <div className="detail">{date}</div>
        <div className="detail">{district}</div>
        <div className="arrow heartbeat" id="myBtn">
          <img
            src={arrow}
            alt="arrow"
            onClick={() => {
              var modal = document.getElementById(place);
              modal.style.display = "flex";
            }}
          />
        </div>
      </div>

      <div id={place} className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Poster</h2>
            <span
              className="close"
              onClick={() => {
                var modal = document.getElementById(place);
                modal.style.display = "none";
              }}
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            <img src={poster} alt="poster" />
            <p>{about}</p>
            <span>
              <a href={link} target="_blank" rel="noreferrer">
                Read more
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
