import axios from "axios";
// import Disabled from "../components/Disabled";

// const user_token = localStorage.getItem("user_token");
// console.log(user_token);

const baseURL = process.env.REACT_APP_API_BASE_URL;

const base = async (options, headerOptions) => {
  try {
    const res = await axios({
      baseURL,
      headers: {
        Accept: "application/json",
        // Authorization: user_token ? `Bearer ${user_token}` : null,
        ...headerOptions,
      },
      ...options,
    });
    return res.data;
  } catch (err) {
    console.log("err", err.response);
    alert(err?.response?.data?.message);
    if (err.response.status === 401) {
      // Disabled();
      alert(err?.response?.data?.message || err?.response?.status);
    }
    throw new Error(err);
  }
};

export const get = (url, params) => {
  const options = {
    method: "get",
    url,
    params,
  };
  return base(options);
};

export const patch = (url, data) => {
  const options = {
    method: "patch",
    url,
    data,
  };
  return base(options);
};

export const post = (url, data, headerOptions) => {
  const options = {
    method: "post",
    url,
    data,
  };
  return base(options, headerOptions);
};

export const put = (url, data) => {
  const options = {
    method: "put",
    url,
    data,
  };
  return base(options);
};

export const del = (url, data) => {
  const options = {
    method: "delete",
    url,
    data,
  };
  return base(options);
};
