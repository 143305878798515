import { createContext, useState } from "react";

const MusicContext = createContext(null);

const MusicProvider = ({ children }) => {
  const [music, setMusic] = useState({});

  return (
    <MusicContext.Provider value={[music, setMusic]}>
      {children}
    </MusicContext.Provider>
  );
};

export { MusicContext, MusicProvider };
