import React from "react";
import "./ShopPage.scss";

export const ShopPage = () => {
  return (
    <div className="ShopPage">
      <div className="shop-sec">
        <h2>Coming soon...</h2>
      </div>
    </div>
  );
};
