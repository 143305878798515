import React, { useContext, useEffect, useState } from "react";
import "./MusicPage.scss";
import { MusicPlayer } from "../components/musicpage/MusicPlayer";
import { PlayListComp } from "../components/musicpage/PlayListComp";
import { LoadingContext } from "../contexts/loadingContext";
import { get } from "../API/base";
import { MusicContext } from "../contexts/musicContext";

export const MusicPage = () => {
  const [loading, setloading] = useContext(LoadingContext);

  const [music, setMusic] = useContext(MusicContext);

  const [allMusic, setAllMusic] = useState([]);

  const [musiclink, setmusiclink] = useState("");
  const [image, setimage] = useState("");
  const [name, setname] = useState("");
  const [year, setyear] = useState("");
  const [lyrics, setlyrics] = useState("");

  useEffect(() => {
    //all music
    setloading(true);
    console.log("%ccurrent music", "color:yellow", music);
    if (music !== null && music !== undefined) {
      setimage(music?.thumbnail);
      setname(music?.name);
      setyear(music?.year);
      setlyrics(music?.lyrics);
      setmusiclink(music?.mp3);
    } else {
      console.log("No current music");
    }
    //all music
    get(`oorali/music/music-view/`)
      .then((data) => {
        console.log("%call music", "color:yellow", data?.data);
        setAllMusic(data?.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="MusicPage">
      <div className="music-sec">
        <div className="music-title">Albums</div>
        <div className="playlist-sec">
          {allMusic?.map((m) => (
            <React.Fragment key={m?.id}>
              <PlayListComp
                setimage={setimage}
                setname={setname}
                setyear={setyear}
                setlyrics={setlyrics}
                musiclink={musiclink}
                setmusiclink={setmusiclink}
                link={m?.mp3}
                image={m?.thumbnail}
                name={m?.name}
                year={m?.year}
                lyrics={m?.lyrics}
              />
            </React.Fragment>
          ))}
        </div>
        {name !== "" && name !== undefined ? (
          <div className="playing-now-sec">
            <div className="title">Now playing</div>
            <div className="player-sec">
              <div className="img-name">
                <img src={image} alt={name} />
                <div className="name">{name}</div>
                <div className="year">{year}</div>
              </div>
              <div
                className="lyrics"
                dangerouslySetInnerHTML={{
                  __html: `<span>${lyrics}</span>`,
                }}
              />
            </div>
          </div>
        ) : null}
        {name !== "" && name !== undefined ? (
          <div className="player">
            <MusicPlayer song={musiclink} cover={image} name={name} />)
          </div>
        ) : null}
      </div>

      {name !== "" && name !== undefined ? (
        <div className="donate">
          <span>
            If you are reading this, we are already connected .The value of what
            we create and share is based on what it means to you. If you feel
            that our effort is making your time worthwhile feel free to donate .
            Your support means a lot to us. Art salute
          </span>
          <button
            className="new-btn-pri"
            onClick={() => {
              var modal = document.getElementById("myDonateModal2");
              modal.style.display = "flex";
            }}
          >
            DONATE
          </button>
        </div>
      ) : null}

      <div id="myDonateModal2" className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h3>{"Donate"}</h3>
            <span
              className="close"
              onClick={() => {
                var modal = document.getElementById("myDonateModal2");
                modal.style.display = "none";
              }}
            >
              &times;
            </span>
          </div>
          <div className="modal-body">
            <div className="bank-details-con">
              <div className="bank">State Bank of India</div>
              <div className="bank-details">
                Account Number: <span> 67212312151</span>
              </div>
              <div className="bank-details">
                IFSC Code:<span> SBIN0070166</span>
              </div>
              <div className="bank-details">
                PAN Number :<span> AAJAS0945A</span>
              </div>
              <div className="bank-details">
                GPAY Number: <span>9061546756</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
