import React from "react";
import Slider from "react-slick";
import "./VideoSlickComp.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const VideoSlickComp = ({ title, data }) => {
  const settings = {
    arrows: true,
    className: "center",
    centerMode: true,
    centerPadding: "0px",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    cssEase: "linear",
    swipeToSlide: true,
    pauseOnHover: true,
    initialSlide: 0,
    // adaptiveHeight: true,
  };

  return (
    <div className="VideoSlickComp">
      <div className="title-style">{title}</div>
      <Slider {...settings}>
        {data?.map((d) => (
          <div key={d?.id}>
            <iframe
              loading="lazy"
              src={d?.youtube_url}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default VideoSlickComp;
