import React from "react";
import "./VideoComp.scss";
import playbutton from "../../images/icons/playbutton1.svg";

export const VideoComp = ({ thumbnail, title, link }) => {
  return (
    <div className="VideoComp">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className="image-con">
          <img className="music-image" src={thumbnail} alt={title} />
          <img className="hover-play" src={playbutton} alt="play" />
        </div>
        <div className="name">{title}</div>
      </a>
    </div>
  );
};
