import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import { SadhanaPage } from "./pages/SadhanaPage";
import { TourPage } from "./pages/TourPage";
import { MediaPage } from "./pages/MediaPage";
import { TimelinePage } from "./pages/TimelinePage";
import { MusicPage } from "./pages/MusicPage";
import { BandPage } from "./pages/BandPage";
import { OoraliExpressPage } from "./pages/OoraliExpressPage";
import { ShopPage } from "./pages/ShopPage";
import { ScrollToTop } from "./ScrollToTop";
import { NewNavbar } from "./components/homepage/NewNavbar";
import { AboutUs } from "./components/homepage/AboutUs";
import Footer from "./components/homepage/Footer";
import { MusicProvider } from "./contexts/musicContext";
import { LoadingProvider } from "./contexts/loadingContext";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <LoadingProvider>
          <NewNavbar />
          <Switch>
            <Route exact path="/ShopPage" component={ShopPage} />
            <Route exact path="/SadhanaPage" component={SadhanaPage} />
            <Route exact path="/TourPage" component={TourPage} />
            <Route exact path="/TimelinePage" component={TimelinePage} />
            <Route
              exact
              path="/OoraliexpressPage"
              component={OoraliExpressPage}
            />
            <Route exact path="/MediaPage" component={MediaPage} />
            <Route exact path="/BandPage" component={BandPage} />
            <MusicProvider>
              <Route exact path="/MusicPage" component={MusicPage} />
              <Route exact path="/" component={Homepage} />
            </MusicProvider>
          </Switch>
          <AboutUs />
          <Footer />
        </LoadingProvider>
      </Router>
    </div>
  );
}

export default App;
