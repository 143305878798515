import React, { useContext, useEffect, useState } from "react";
import { TourComp } from "../components/tourpage/TourComp";
import "./TourPage.scss";
import { LoadingContext } from "../contexts/loadingContext";
import { get } from "../API/base";

export const TourPage = () => {
  const [loading, setloading] = useContext(LoadingContext);

  const [tours, setTours] = useState([]);

  useEffect(() => {
    setloading(true);
    //all tours
    get(`oorali/tours/tour-view/`)
      .then((data) => {
        console.log("%call tours", "color:yellow", data.data);
        setTours(data?.data);
        setloading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="TourPage">
      <div className="tour-con">
        <div className="upcoming">Upcoming Tours</div>
        <div className="tour-comps">
          {tours?.length !== 0 ? (
            tours?.map((t) => (
              <React.Fragment key={t?.id}>
                <TourComp
                  place={t?.place}
                  date={t?.date}
                  district={t?.district}
                  poster={t?.poster}
                  about={t?.about}
                  link={t?.link}
                />
              </React.Fragment>
            ))
          ) : (
            <h2>No upcoming tours !</h2>
          )}
        </div>
      </div>
    </div>
  );
};
